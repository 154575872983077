import { useState } from 'react';
import { Button, Text, TextInput, Select, Table, Combobox, InputBase, useCombobox, NumberInput, SimpleGrid, Paper, Group, rem, Avatar, ActionIcon } from '@mantine/core';
import { saveClientGeneralSettings, archiveClient, uploadClientImage } from '../../data/dataApi';
import { useUserState } from './UserStateContext';
import { showSuccessNotification, showUnexpectedErrorNotification, projectTypes, hosts } from '../helpers';
import { DatePickerInput } from '@mantine/dates';
import ClientContacts from './ClientContacts';
import { IconArchive, IconUpload, IconX, IconPhoto, IconPencil } from '@tabler/icons-react';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';

const ClientGeneralSettings = ({ client, onClientChange }) => {
    const { users } = useUserState();
    const [clientName, setClientName] = useState(client.name);
    const [clientType, setClientType] = useState(client.type);
    const [projectLead, setProjectLead] = useState(client.project_lead);
    const [designLead, setDesignLead] = useState(client.design_lead);
    const [techLead, setTechLead] = useState(client.tech_lead);
    const [websiteUrl, setWebsiteUrl] = useState(client.website_url);
    const [hostsData, setHostsData] = useState(hosts);
    const [host, setHost] = useState(client.host);
    const [hostSearch, setHostSearch] = useState(client.host);
    const [totalBudget, setTotalBudget] = useState(client.total_budget);
    const [contractStartDate, setContractStartDate] = useState(client.contract_start_date ? new Date(client.contract_start_date) : null);
    const [contractEndDate, setContractEndDate] = useState(client.contract_end_date ? new Date(client.contract_end_date) : null);

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const exactHostMatch = hostsData.some((item) => item === hostSearch);
    const filteredHosts = exactHostMatch
        ? hostsData
        : hostSearch ? hostsData.filter((item) => item.toLowerCase().includes(hostSearch.toLowerCase().trim())) : [];

    const hostsOptions = filteredHosts.map((item) => (
        <Combobox.Option value={item} key={item}>
            {item}
        </Combobox.Option>
    ));

    const handleSave = async () => {
        const response = await saveClientGeneralSettings(client.id, {
            clientName,
            clientType,
            host,
            projectLead,
            designLead,
            techLead,
            websiteUrl,
            totalBudget,
            contractStartDate: contractStartDate ? new Date(contractStartDate.setHours(0, 0, 0, 0)) : null,
            contractEndDate: contractEndDate ? new Date(contractEndDate.setHours(0, 0, 0, 0)) : null,
        });

        if (response.success) {
            onClientChange(prevState => ({ ...prevState, ...response.data.rows[0] }));

            showSuccessNotification("Client settings saved successfully.");
        } else {
            showUnexpectedErrorNotification("There was an error saving the client settings. Please try again.");
        }
    };

    const handleArchiveBtnClick = async () => {
        const response = await archiveClient(client.id, { archived: !client.archived });

        if (response.success) {
            onClientChange(prevState => ({ ...prevState, archived: !prevState.archived }));

            showSuccessNotification(`Client ${client.archived ? "unarchived" : "archived"} successfully.`);
        } else {
            showUnexpectedErrorNotification();
        }
    };

    const handleFileUpload = async file => {
        const response = await uploadClientImage(file, client.id);

        if(response.success) {
            onClientChange(prevState => ({ ...prevState, avatar_url: response.data.avatar_url }));

            showSuccessNotification("Client image uploaded successfully.");
        } else {
            showUnexpectedErrorNotification();
        }
    };

    return (
        <div className="d-flex flex-column flex-grow-1">
            <label className="tif-font-sm">Client Image</label>
            {
                client.avatar_url ?
                        <Dropzone
                            className="client-image-wrapper position-relative rounded-circle overflow-hidden tif-mb-20"
                            onDrop={handleFileUpload}
                            onReject={showUnexpectedErrorNotification}
                            maxSize={5 * 1024 ** 2}
                            accept={IMAGE_MIME_TYPE}
                            maxFiles={1}
                        >
                            <Avatar src={client.avatar_url} alt="Client avatar" size={60} />
                            <ActionIcon variant="subtle" color="white" aria-label="Settings" className="position-absolute top-0 w-100 h-100">
                                <IconPencil style={{ width: '24px', height: '24px' }} stroke={1.5} />
                            </ActionIcon>
                        </Dropzone>

                :
                    <Paper radius="md" bg="var(--mantine-color-dark-6)" mb="20px">
                        <Dropzone
                            className="profile-image-upload"
                            onDrop={handleFileUpload}
                            onReject={showUnexpectedErrorNotification}
                            maxSize={5 * 1024 ** 2}
                            accept={IMAGE_MIME_TYPE}
                            maxFiles={1}
                        >
                            <Group justify="center" gap="xl" mih={100} style={{ pointerEvents: 'none' }}>
                                <Dropzone.Accept>
                                    <IconUpload
                                        style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                                        stroke={1.5}
                                    />
                                </Dropzone.Accept>
                                <Dropzone.Reject>
                                    <IconX
                                        style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                                        stroke={1.5}
                                    />
                                </Dropzone.Reject>
                                <Dropzone.Idle>
                                    <IconPhoto
                                        style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }}
                                        stroke={1.5}
                                    />
                                </Dropzone.Idle>
        
                                <div>
                                    <Text size="xl" inline>
                                        Drag an image here or click to select a file
                                    </Text>
                                    <Text size="sm" c="dimmed" inline mt={7}>
                                        The file should not exceed 5MB
                                    </Text>
                                </div>
                            </Group>
                        </Dropzone>
                    </Paper>
            }

            <TextInput
                label="Name"
                defaultValue={client.name}
                value={clientName}
                className="tif-mb-20"
                onChange={e => setClientName(e.target.value)}
            />

            <Select
                value={clientType}
                data={projectTypes}
                onChange={value => setClientType(value)}
                allowDeselect={false}
                label="Client Type"
                mb="20px"
            />

            <TextInput
                label="Website URL"
                defaultValue={client.website_url}
                value={websiteUrl}
                className="tif-mb-20"
                onChange={e => setWebsiteUrl(e.target.value)}
            />

            <ClientContacts contacts={client.contacts} onChange={onClientChange} id={client.id} className="tif-mb-20" />

            <Combobox
                label="Host"
                store={combobox}
                className="tif-mb-20"
                withinPortal={false}
                onOptionSubmit={(val) => {
                    if (val === '$create') {
                        setHostsData((current) => [...current, hostSearch]);
                        setHost(hostSearch);
                    } else {
                        setHost(val);
                        setHostSearch(val);
                    }

                    combobox.closeDropdown();
                }}
            >
                <Combobox.Target>
                    <InputBase
                        rightSection={<Combobox.Chevron />}
                        value={hostSearch}
                        onChange={(event) => {
                            combobox.openDropdown();
                            combobox.updateSelectedOptionIndex();
                            setHostSearch(event.currentTarget.value);
                        }}
                        onClick={() => combobox.openDropdown()}
                        onFocus={() => combobox.openDropdown()}
                        onBlur={() => {
                            combobox.closeDropdown();
                            setHostSearch(host || '');
                        }}
                        placeholder="Search host"
                    />
                </Combobox.Target>

                <Combobox.Dropdown>
                    <Combobox.Options>
                        {hostsOptions}
                        {!exactHostMatch && hostSearch && hostSearch.trim().length > 0 && (
                            <Combobox.Option value="$create">+ Create {hostSearch}</Combobox.Option>
                        )}
                    </Combobox.Options>
                </Combobox.Dropdown>
            </Combobox>

            <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">Team</Text>
            <Table className="tif-mb-20">
                <Table.Thead>
                    <Table.Tr style={{ height: 65 }}>
                        <Table.Th className="fw-normal">Project Lead</Table.Th>
                        <Table.Td>
                            <Select
                                placeholder="Project Lead"
                                value={projectLead}
                                data={users.map(user => user.name).sort((a, b) => a.localeCompare(b))}
                                onChange={value => setProjectLead(value)}
                                searchable
                                allowDeselect
                            />
                        </Table.Td>
                    </Table.Tr>
                    <Table.Tr style={{ height: 65 }}>
                        <Table.Th className="fw-normal">Design Lead</Table.Th>
                        <Table.Td>
                            <Select
                                placeholder="Design Lead"
                                value={designLead}
                                data={users.map(user => user.name).sort((a, b) => a.localeCompare(b))}
                                onChange={value => setDesignLead(value)}
                                searchable
                                allowDeselect
                            />
                        </Table.Td>
                    </Table.Tr>
                    <Table.Tr style={{ height: 65 }} className="border-0">
                        <Table.Th className="fw-normal">Tech Lead</Table.Th>
                        <Table.Td>
                            <Select
                                placeholder="Tech Lead"
                                value={techLead}
                                data={users.map(user => user.name).sort((a, b) => a.localeCompare(b))}
                                onChange={value => setTechLead(value)}
                                searchable
                                allowDeselect
                            />
                        </Table.Td>
                    </Table.Tr>
                </Table.Thead>
            </Table>

            <NumberInput
                label="Total Budget"
                defaultValue={client.total_budget}
                value={totalBudget}
                className="tif-mb-20"
                onChange={setTotalBudget}
            />

            {
                client.type === "Maintenance" ?
                    <SimpleGrid cols={{ base: 1, md: 2 }} mb="20px">
                        <DatePickerInput
                            label="Contract Start Date"
                            value={contractStartDate}
                            onChange={setContractStartDate}
                            className="flex-grow-1 flex-shrink-0"
                            maxDate={contractEndDate}
                            clearable
                            valueFormat="MM/DD/YYYY"
                        />
                        <DatePickerInput
                            label="Contract End Date"
                            value={contractEndDate}
                            onChange={setContractEndDate}
                            className="flex-grow-1 flex-shrink-0"
                            minDate={contractStartDate}
                            clearable
                            valueFormat="MM/DD/YYYY"
                        />
                    </SimpleGrid>
                    :
                    <></>
            }

            <Button onClick={handleSave} variant="filled" mb="20px" fullWidth>Save</Button>

            <Button variant={client.archived ? "outline" : "filled"} color="orange" onClick={handleArchiveBtnClick} leftSection={<IconArchive size={16} />} size="compact-sm">{client.archived ? "Unarchive" : "Archive"} Client</Button>
        </div>
    );
};

export default ClientGeneralSettings;