import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { checkUser, createIssueWatchAlert } from '../../data/dataApi';
import { useUserState } from './UserStateContext';
import { Modal, Select, Button } from '@mantine/core';
import { showSuccessNotification, showUnexpectedErrorNotification } from '../helpers';

const RouteHandler = ({ element, requireAuth, admin, excludeAuth, user }) => {
    const [loading, setLoading] = useState(true);
    const { issueWatchModalOpen, closeIssueWatchModal, selectedWatchIssue } = useUserState();
    const [watchIssueType, setWatchIssueType] = useState(null);

    const { setLoggedIn } = useUserState();

    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        (async () => {
            if(!excludeAuth) {
                const response = await checkUser();

                setLoggedIn(!!response.id);

                if(response.id && !requireAuth || (admin && response.role !== "ops") || (id && user && response.id !== Number(id))) {
                    navigate("/home", { replace: true });
                } else if(!response.id && requireAuth) {
                    navigate("/", { replace: true });
                }
            }
            
            setLoading(false);
        })();
    }, [navigate]);

    const handleSaveWatchAlert = async () => {
        const response = await createIssueWatchAlert(selectedWatchIssue, watchIssueType.value);

        if(response.success) {
            showSuccessNotification("Issue watch alert created successfully.");
            closeIssueWatchModal();
        } else {
            showUnexpectedErrorNotification();
        }
    };

    if(loading) {
        return <></>;
    } else {
        return (
            <>
                {element}
                <Modal opened={issueWatchModalOpen} onClose={closeIssueWatchModal} title="Watch Issue">
                    <Select
                        label="Type"
                        placeholder="Select type"
                        data={[{
                            value: "time_spent_overage",
                            label: "Time Spent Overage"
                        }]}
                        value={watchIssueType ? watchIssueType.value : null}
                        onChange={(_value, option) => setWatchIssueType(option)}
                    />

                    <Button fullWidth mt={20} onClick={handleSaveWatchAlert}>Save</Button>
                </Modal>
            </>
        );
    }
};

export default RouteHandler;