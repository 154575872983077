import { Table } from "@mantine/core";
import { Avatar, Badge, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { priorityColors, extractTextFromContent } from "../helpers.js";

const TicketOverviewTable = ({ issues, queries, tab }) => {
    return (
        <Table verticalSpacing="sm" highlightOnHover>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th style={{ minWidth: "175px" }}>Assignee</Table.Th>
                    <Table.Th>Summary</Table.Th>
                    <Table.Th>Description</Table.Th>
                    <Table.Th className="text-nowrap">Time Spent</Table.Th>
                    <Table.Th>Updated</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {
                    issues.length ?
                        issues.map((issue, index) => (
                            <Table.Tr key={index} className="cursor-pointer" style={{ color: (issue.fields.priority?.name !== "Medium") ? priorityColors[issue.fields.priority?.name] : ""}}onClick={() => window.open(`https://thinkitfirst.atlassian.net/browse/${issue.key}`, '_blank')}>
                                <Table.Td className="d-flex align-items-center">
                                    <Avatar size={25} src={issue.fields.assignee?.avatarUrls['48x48']} className="flex-shrink-0 tif-mr-10" />
                                    <Badge color={priorityColors[issue.fields.priority?.name]} className={`position-relative flex-shrink-0 ${(issue.fields.priority?.name === "Highest") ? "pulse-animation" : ""}`} variant="light">
                                        {issue.key}
                                    </Badge>
                                </Table.Td>
                                <Table.Td>
                                    <Text fz="sm" fw={700} style={{ minWidth: 0 }} truncate="end" className="position-relative">
                                        {issue.fields.summary}
                                    </Text>
                                </Table.Td>
                                <Table.Td className="truncate">
                                    <Text fz="sm" style={{ minWidth: 0 }} truncate="end" className="position-relative">
                                        {issue.fields.description ? extractTextFromContent(issue.fields.description.content) : ""}
                                    </Text>
                                </Table.Td>
                                <Table.Td>
                                    <Text fz="sm">{(issue.fields.aggregatetimespent / 3600).toFixed(1)}h</Text>
                                </Table.Td>
                                <Table.Td className="text-nowrap">
                                    <Text fz="sm">{new Date(issue.fields.updated).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</Text>
                                </Table.Td>
                            </Table.Tr>
                        ))
                    :
                        <Text mt="10px">0 tickets.</Text>
                }
            </Table.Tbody>
            <Table.Caption className="text-center">
                <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=${queries[tab]}`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
            </Table.Caption>
        </Table>
    );
};

export default TicketOverviewTable;