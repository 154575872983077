import { Paper } from "@mantine/core";

const LoginScreen = ({ children }) => {
    const bgImage = require(`../../images/bg-${Math.floor(Math.random() * 21) + 1}.jpg`);
  
    return(
        <div id="login-screen" className="d-flex" style={{ "--bg": `url(${bgImage})` }}>
            <div className="overlay flex-grow-1"></div>
            <Paper className="login-wrapper" radius="0" p="30">
                { children }
            </Paper>
        </div>
    );
};

export default LoginScreen;